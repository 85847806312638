import React, { Component, Fragment } from 'react';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { TrackingMap } from 'client/tracking/maps/industry-center/index';
import { TrackingHandler } from 'client/tracking/handler';
import { renderEntries } from 'site-modules/industry-center/utils/entries-renderer';
import { IndustryPageSubNavigation } from 'site-modules/industry-center/components/sub-navigation/industry-page-sub-navigation';

const SUB_NAVIGATOR_ID = 'sub-navigation';

export class IndustryPage extends Component {
  static propTypes = {
    content: CmsEntities.Content,
  };

  static defaultProps = {
    content: DEFAULT_CONTENT,
  };

  componentDidMount() {
    TrackingHandler.useMap(TrackingMap);
    // eslint-disable-next-line no-underscore-dangle
    window.__carcode = {
      // $zindex-navbar - 1
      zIndex: 999,
    };
  }

  shouldComponentUpdate(nextProps) {
    return this.props.content !== nextProps.content;
  }

  render() {
    const { content } = this.props;
    const contentEntries = content.children();
    const subNav = content.child(SUB_NAVIGATOR_ID);
    return (
      <Fragment>
        <IndustryPageSubNavigation subNav={subNav} />
        {renderEntries({ entriesToRender: contentEntries })}
      </Fragment>
    );
  }
}
