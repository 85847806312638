import React from 'react';
import { isEmpty } from 'lodash';
import { pageDefinition } from 'site-modules/shared/pages/page';
import { ModelPreloader } from 'client/data/luckdragon/redux/model-preloader';
import { CmsModel, bindToContent } from 'client/data/models/cms';
import { updateExtTagAttribute } from 'client/actions/external-tag-attribute';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { handle404Error } from 'client/site-modules/shared/utils/errors';
import { IndustryCenterDecorator } from 'site-modules/shared/pages/decorators/industry-center-decorator';
import { getSeoObject } from 'client/site-modules/industry-center/utils/seo';
import { IndustryPage } from './industry-page';

/**
 * Returns content path id by url pathname.
 *
 * @param {String} pageName - pageName
 * @return {String} content path.
 */
export function getContentPath(pageName = 'index') {
  return `/industry/${pageName},/${pageName}&fetchcontents=true`;
}

export const connectToModelConfiguration = {
  content: bindToContent(props => {
    const { pageName } = props.match.params;
    return getContentPath(pageName);
  }),
};

export const IndustryPageUI = connectToModel(IndustryPage, connectToModelConfiguration);

export default pageDefinition({
  name: 'industry',
  category: 'industry',
  preload: (store, props) => {
    const { pageName } = props.match.params;
    const preloader = new ModelPreloader(store);
    const articlePath = getContentPath(pageName);
    preloader.resolve(`content["${articlePath}"]`, CmsModel);

    return preloader
      .load()
      .then(modelState => {
        const content = modelState.get(`content["${articlePath}"]`, CmsModel);
        if (isEmpty(content)) {
          throw handle404Error({ errorMessage: 'IndustryPageDefinition - Content is empty' });
        } else {
          store.dispatch(updateExtTagAttribute(content.contentMetadata.extTagAttribute));
        }
      })
      .catch(error => {
        const ex = error;
        /*
         * Only redirect to 404 if article api returned 404
         * Original message:
         * `Request to ${url} failed, got status 404`
         */
        if (error.message.includes(articlePath) && error.message.includes('got status 404')) {
          ex.redirectPageTo404 = true;
        }
      });
  },
  component: props => <IndustryPageUI {...props} />,
  decorator: IndustryCenterDecorator,
  seo: (state, props) => {
    const { params } = props.match;
    return getSeoObject(state, getContentPath(params.pageName));
  },
});
